<template>
  <!-- Generator: Adobe Illustrator 26.0.2, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
  <svg
    id="Layer_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 77 50"
    style="enable-background: new 0 0 77 50"
    xml:space="preserve"
  >
    <path
      class="st0"
      d="M38.4,3.1c4.8,0,8.7,3.9,8.7,8.7s-3.9,8.7-8.7,8.7s-8.7-3.9-8.7-8.7S33.6,3.1,38.4,3.1 M38.4,0.1
  c-6.5,0-11.7,5.2-11.7,11.7c0,6.5,5.2,11.7,11.7,11.7s11.7-5.2,11.7-11.7C50.1,5.3,44.8,0.1,38.4,0.1L38.4,0.1z"
    />
    <path
      class="st0"
      d="M58.3,49.6c-0.8,0-1.4-0.6-1.4-1.4c0-10.2-8.3-18.4-18.4-18.4C28.3,29.8,20,38.1,20,48.2c0,0.8-0.6,1.4-1.4,1.4
  s-1.4-0.6-1.4-1.4c0-11.7,9.5-21.2,21.2-21.2s21.2,9.5,21.2,21.2C59.7,49,59,49.6,58.3,49.6z"
    />
    <g>
      <g>
        <path
          class="st0"
          d="M15,16.2c3,0,5.4,2.4,5.4,5.4S18,27,15,27s-5.4-2.4-5.4-5.4S12,16.2,15,16.2 M15,13.2c-4.7,0-8.4,3.8-8.4,8.4
      S10.3,30,15,30s8.4-3.8,8.4-8.4S19.6,13.2,15,13.2L15,13.2z"
        />
        <path
          class="st0"
          d="M1.7,49.7c-0.8,0-1.5-0.7-1.5-1.5c0-8.1,6.6-14.7,14.7-14.7c2.8,0,5.5,0.8,7.8,2.2c0.7,0.4,0.9,1.4,0.5,2.1
      c-0.4,0.7-1.4,0.9-2.1,0.5c-1.9-1.2-4-1.8-6.2-1.8c-6.5,0-11.7,5.3-11.7,11.7C3.2,49.1,2.5,49.7,1.7,49.7z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M62,16.2c3,0,5.4,2.4,5.4,5.4S65,27,62,27s-5.4-2.4-5.4-5.4S59,16.2,62,16.2 M62,13.2c-4.7,0-8.4,3.8-8.4,8.4
      S57.4,30,62,30s8.4-3.8,8.4-8.4S66.7,13.2,62,13.2L62,13.2z"
        />
        <path
          class="st0"
          d="M75.3,49.7c-0.8,0-1.5-0.7-1.5-1.5c0-6.5-5.3-11.7-11.7-11.7c-2.2,0-4.3,0.6-6.2,1.8
      c-0.7,0.4-1.6,0.2-2.1-0.5s-0.2-1.6,0.5-2.1c2.3-1.5,5-2.2,7.8-2.2c8.1,0,14.7,6.6,14.7,14.7C76.8,49.1,76.2,49.7,75.3,49.7z"
        />
      </g>
    </g>
  </svg>
</template>
<style scoped>
.st0 {
  fill: #373636;
}
svg {
  width: 86px;
}
</style>
